import React from "react";
import loadable from "@loadable/component";
import classNames from "classnames";
import brand from "@utils/brand";
import { Carousel, CardEvent, PullQuote, HeadingWIcon } from "@molecules";
import { Container, Text } from "@atoms";

const ContentBuilder = loadable(() => import("@organisms/ContentBuilder"));

const HistorySection = ({
  color,
  heading,
  contentBuilder,
  pullQuote,
  cards,
}) => {
  const { lakota, romero, lgca } = brand;
  return (
    <div
      className={classNames("w-full overflow-hidden py-12 sm:py-24", {
        "bg-blue": color === "blue",
        "bg-blue-light": color === "light blue",
        "bg-white": color === "white",
        "bg-gray-light": color === "gray",
        "bg-brown": color === "brown",
        "bg-cream": color === "cream" || !color,
      })}
    >
      <Container>
        <div
          className={classNames("flex flex-wrap items-start md:flex-nowrap", {
            "gap-16 md:gap-32": lakota,
            "gap-12 md:gap-24": romero,
          })}
        >
          <div
            className={classNames("space-y-3", {
              "w-full md:w-3/4": pullQuote,
            })}
          >
            {(lakota || lgca) && (
              <HeadingWIcon
                heading={heading}
                icon={lakota ? "sunrise" : null}
              />
            )}
            {romero && (
              <Text
                variant="h2"
                className={classNames("", {
                  "text-blue": color !== "blue",
                  "text-white": color === "blue",
                })}
              >
                {heading}
              </Text>
            )}
            <div
              className={classNames("space-y-3", {
                "text-white": color === "brown" || color === "blue",
              })}
            >
              <ContentBuilder
                inverse={color === "brown" || color === "blue"}
                blocks={contentBuilder}
              />
            </div>
          </div>
          {pullQuote && <PullQuote {...pullQuote} color={color} />}
        </div>
        {cards?.length > 2 && (
          <div className="-mx-3 mt-8 md:mt-12">
            <Carousel
              showMultiple
              maxVisible={3}
              centerItems
              itemsStart
              gradient={lakota ? "gray-light" : null}
            >
              {cards.map(card => (
                <div className="group px-3">
                  <CardEvent {...card} />
                </div>
              ))}
            </Carousel>
          </div>
        )}
        {cards?.length <= 2 && !!cards?.length && (
          <div className="-mx-3 mt-8 flex flex-wrap md:mt-12">
            {cards.map(card => (
              <div className="group w-full px-3 md:w-1/3">
                <CardEvent {...card} />
              </div>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

HistorySection.defaultProps = {
  color: "cream",
};

export default HistorySection;
